import React from "react";
import dynamic from "next/dynamic";

import NextHead from "~/components/common/next-head";
import ProductIntro from "~/components/common/partials/product-intro";
import ProductDetails from "~/components/common/partials/product-details";
import Banner1 from "~/components/common/partials/banner-1";
import KeySpecifications from "~/components/common/partials/key-specifications";
import Routine from "~/components/common/partials/routine";
import BrandProps from "~/components/common/partials/brand-props";
import IngredientSpecifications from "~/components/common/partials/ingredients-specifications";
// import FAQ from "~/components/common/partials/faq";
import Details from "~/components/common/partials/details";
import CustomerReview from "~/components/common/partials/customer-review";
import StickyCheckout from "~/components/common/sticky-checkout";
import data from "~/data.json";
import { getPublicImageURL } from "~/utils/getPublicImageUrl";
import fetchData from "~/utils/fetchData";
import { STORE_ID, GOOGLE_VERIFICATION_TAG } from "~/config";
import { getStoreBanners, getProductBySlug } from "~/graphql/api";
import FAQ from "../components/faq";
import { searchProductFaqs } from "~/graphql/queries";
import Header from "~/components/common/header";
import Footer from "~/components/common/footer";

const BrandSection = dynamic(() =>
  import("~/components/partials/home/brand-section")
);

const pageSlug = "apple-cider-vinegar-shampoo-set";

export const getStaticProps = async () => {
  try {
    const getProductBySlugResponse = fetchData(getProductBySlug, {
      limit: 1,
      slug: pageSlug,
      filter: {
        storeId: { eq: STORE_ID },
        status: { eq: "ENABLED" },
      },
      variantFilter: {
        status: { eq: "ENABLED" },
      },
    });

    const getStoreData = fetchData(getStoreBanners, { id: STORE_ID });

    const [
      {
        byslugProduct: {
          items: [product],
        },
      },
      { getStore: store },
    ] = await Promise.all([getProductBySlugResponse, getStoreData]);
    const { pageTitle, productDescription, title, metadata } = product;

    const { name, webUrl, imageUrl } = store;
    const getFaqs = fetchData(searchProductFaqs, {
      filter: {
        productId: { eq: product.id },
      },
    });

    const [
      {
        searchProductFaqs: { items: faqs },
      },
    ] = await Promise.all([getFaqs]);

    const brands = [
      "/images/brands/brand1.png",
      "/images/brands/brand2.png",
      "/images/brands/brand3.png",
      "/images/brands/brand4.webp",
      "/images/brands/brand5.png",
      "/images/brands/brand6.png",
    ];

    return {
      props: {
        faqs,
        product,
        brands,
        pageMeta: {
          siteName: name,
          title: metadata?.title || title || pageTitle,
          description: metadata?.description || productDescription,
          keywords: metadata?.keywords || [],
          canonical: `${webUrl}/products/${pageSlug}`,
          image: getPublicImageURL(imageUrl),
        },
      },
      revalidate: 60,
    };
  } catch (error) {
    console.log(error);
    return {
      notFound: true,
    };
  }
};

function HomePage({ brands, pageMeta, product, faqs }) {
  const { [pageSlug]: productData } = data;

  return (
    <main className="main home searchBar">
      <NextHead {...pageMeta} />

      <h1 className="d-none">APPLE CIDER VINEGAR SHAMPOO SET</h1>
      <div className="page-content page-content-wrapper">
        <Header hasFaq={true} />
        <Details id={pageSlug} product={product} />
        <ProductIntro data={productData.introduction} id={pageSlug} />
        <BrandSection brands={brands} id={pageSlug} />
        <ProductDetails data={productData.expertAdvice} id={pageSlug} />
        <Banner1 data={productData.midBanner} id={pageSlug} />
        <KeySpecifications id={pageSlug} />
        <Routine data={productData.routine} id={pageSlug} />
        <CustomerReview id={pageSlug} />
        <BrandProps id={pageSlug} />
        <IngredientSpecifications id={pageSlug} />
        {/* <FAQ data={productData.faqSection} id={pageSlug} /> */}
        <FAQ faqs={faqs} data={productData.faqSection} />
        <StickyCheckout />
        <Footer />
      </div>
    </main>
  );
}

HomePage.showStickyCheckout = true;
HomePage.showTopRunner = true;
export default HomePage;
